.register {
    @extend .container;
    position: relative;
    max-width: rem(800px);
    margin-top: rem(120px);
    margin-bottom: rem(120px);

    @include media-breakpoint-down(lg) {
        margin-top: rem(60px);
        margin-bottom: rem(60px);
    }

    &--is-busy {
        box-shadow: 0 0 5px rgba($black-color, .05);

        > .card__body > * {
            opacity: .25;
        }

        .loader {
            display: block !important;
            opacity: 1 !important;
        }
    }

    &--step-1 {
    }

    &--step-2,
    &--step-3 {
        h1,
        h2,
        h3,
        h4,
        p {
            text-align: center;
        }
    }

    .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(40px);
    }

    .btn {
        width: 100%;
        margin-bottom: rem(25px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    > .card__body > * {
        transition: opacity .3s $easeOutExpo;
    }

    .card__body {
        display: flex;
        flex-direction: column;
    }

    .loader {
        display: none;
    }
}
