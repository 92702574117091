.radio-card {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    transform: translate3d(0, 0, 0);

    &__label {
        @extend .card;
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        text-align: center;
        color: $grey-color;
        padding-left: 0 !important;
        transition: color .3s, border .3s;

        &:hover {
            border-color: $grey-color;

            .radio-card__header {
                background-color: #fafafa;
            }
        }

        &::before,
        &::after {
            content: inherit !important;
            content: initial !important;
        }
    }

    &__input {
        position: absolute;
        opacity: 0;
    }

    &__header {
        display: flex;
        justify-content: center;
        flex: 1 0 auto;
        padding: 15px;
        color: inherit;
        background-color: inherit;
        transition: color .3s, background-color .3s;

        * {
            color: inherit;
        }

        & + .radio-card__content:not(:empty) {
            border-top: rem(1px) solid $light-grey-color;
        }
    }

    &__content {
        flex: 0 0 auto;
        padding: 15px;
        transform: translate3d(0, 0, 0);

        &:empty {
            padding: 0;
        }
    }

    > input:checked + label {
        border-color: $blue;
        color: $black-color;

        .radio-card__header {
            background-color: #efefef;

            &,
            * {
                color: $blue !important;
            }
        }
    }
}
