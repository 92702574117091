$table--padding: 10px !default;
$table--min-width: 200px !default;
$table--background-color: #ffffff !default;
$table--striped-background-color: #fcfcfc !default;
$table--head-background-color: #fafafa !default;
$table--bordered-border-color: #eeeeee !default;

.table {
    width: calc(100% - 4px);
    margin-bottom: rem(20px);
    border-radius: rem(5px);
    background: $table--background-color;
    margin-left: rem(2px);
    box-shadow: 0 0 0 2px $table--bordered-border-color;

    @include media-breakpoint-down(md) {
        background-color: transparent;
        box-shadow: none;
    }

    th {
        background-color: $table--head-background-color;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    th,
    td {
        padding: $table--padding;
        box-sizing: border-box;
        //min-width: $table--min-width;
    }

    th {
        padding-top: rem(5px);
        padding-bottom: rem(5px);
    }

    td {
        @include media-breakpoint-down(md) {
            display: flex;
            align-items: center;

            &::before {
                content: attr(data-label);
                display: inline-block;
                margin-right: rem(10px);
                font-weight: bold;
                width: rem(80px);
                text-align: left;
                flex: 0 0 auto;
            }
        }
    }

    tr {
        @include media-breakpoint-down(md) {
            background-color: $table--background-color;
            border-radius: rem(5px);
            box-shadow: 0 0 0 2px $table--bordered-border-color;
        }
    }

    &.table--bordered {
        border-collapse: collapse;

        th,
        td {
            border: 1px solid $table--bordered-border-color;
        }
    }

    &.table--striped {
        tbody {
            tr:nth-child(even) {
                background-color: $table--striped-background-color;
            }
        }
    }

    &.table--bordered-striped {
        @extend .table--striped;
        @extend .table--bordered;
    }
}

// responsive table
.table {
    @include media-breakpoint-down(md) {
        thead {
            display: none;
        }

        td {
            display: flex;
            align-items: center;
            word-break: break-all;

            &::before {
                content: attr(data-label);
                display: inline-block;
                margin-right: 10px;
                font-weight: bold;
                width: rem(120px);
                text-align: left;
                flex: 0 0 auto;
            }
        }

        tr {
            display: block;
            margin-bottom: rem(20px);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.table--striped {
        @include media-breakpoint-down(md) {
            tbody {
                tr:nth-child(even) {
                    background-color: inherit;
                }

                td {
                    &:not(:first-child) {
                        margin-top: -1px;
                    }

                    &:nth-child(odd) {
                        background-color: $table--striped-background-color;
                    }
                }
            }

        }
    }
}
