$hr--color: $light-grey-color !default;
$hr--height: 1px !default;
$hr--border-size: 1px !default;

hr {
    background: $hr--color;
    border: $hr--border-size;
    height: $hr--height;
    margin: 0;
}
