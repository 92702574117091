// grid breakpoints in higher education package
// should be declared within rem() function
// to guarantee the right parsing of the media-queries
$xs: rem(0) !default;
$sm: rem(576px) !default;
$md: rem(768px) !default;
$lg: rem(992px) !default;
$xl: rem(1200px) !default;
$xxl: rem(1600px) !default;

$grid-gutter-width: rem(40px) !default;
$grid-cols: 12 !default;
$grid-breakpoints: (
        xs: $xs,
        sm: $sm,
        md: $md,
        lg: $lg,
        xl: $xl,
        xxl: $xxl
) !default;

.grid {
    @include make-row();

    .grid__column {
        flex: 1 1 auto;
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2);
        flex-basis: 100%;
        width: 100%;
        max-width: 100%;
        display: flex;
        align-content: flex-start;
        flex-direction: column;

        @each $breakpointName, $breakpoint in $grid-breakpoints {
            @include media-breakpoint-up($breakpointName) {
                @for $i from 1 through $grid-cols {
                    &.grid__column--#{$breakpointName}-#{$i} {
                        @include make-column($i, $grid-cols);
                    }

                    &.grid__column--#{$breakpointName}-order-first {
                        order: -1;
                    }

                    &.grid__column--#{$breakpointName}-order-last {
                        order: $grid-cols + 1;
                    }

                    &.grid__column--#{$breakpointName}-order-#{$i} {
                        order: $i;
                    }

                    &.grid__column--#{$breakpointName}-offset-#{$i} {
                        @include make-column-offset($i);
                    }
                }
            }
        }

    }
}
