.dropdown-wrapper {
    @include clearfix;
    position: relative;
}

.dropdown {
    @extend .card;
    display: block;
    position: absolute;
    top: calc(100% + 10px);
    padding: 0;
    min-width: 120px;
    box-shadow: rem(0 1px 14px 0) rgba(0, 0, 0, 0.2);
    z-index: 50;
    overflow: visible;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transition: opacity .2s, visibility .2s, transform .2s $easeOutBack;

    &[hidden] {
        display: block !important;
        opacity: 0;
        visibility: hidden;
        transform: scale(0.7);
        transition: opacity .1s, visibility .1s, transform .1s;
    }

    &::before {
        content: " ";
        background-color: white;
        position: absolute;
        width: rem(20px);
        height: rem(20px);
        top: rem(-6px);
        transform: rotate(45deg);
        border: 1px solid rgba(0, 0, 0, .125);
        z-index: -1;
        left: 10px;
    }

    &--left {
        left: 0;
        transform-origin: 20px 0%;
    }

    &--center {
        left: 50%;
        transform: translateX(-50%);
        transform-origin: -100% 0%;
    }

    &--right {
        right: 0;
        transform-origin: calc(100% - 20px) 0%;
    }

    &--right-desktop {
        @include media-breakpoint-up(lg) {
            right: 0;
            transform-origin: calc(100% - 20px) 0%;
        }
    }

    &--arrow-left::before {
        left: rem(10px);
    }

    &--arrow-center::before {
        left: 50%;
        top: rem(0);
        transform: rotate(45deg) translateX(-50%);
    }

    &--arrow-right::before {
        right: rem(10px);
        left: inherit;
    }

    &--arrow-right-desktop::before {
        @include media-breakpoint-up(lg) {
            right: rem(10px);
            left: inherit;
        }
    }

    &__content {
        position: relative;
        background: white;
        padding: rem(10px 20px);
        border-radius: inherit;
        z-index: 5;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

        li {
            padding-top: rem(3px);
            padding-bottom: rem(3px);

            &:not(:last-child) {
                border-bottom: 1px solid $light-grey-color;
            }

            button {
                display: block;
                width: 100%;
                text-align: left;
                padding-left: 0;
                padding-right: 0;
            }

            svg {
                margin-right: rem(5px);
            }
        }
    }
}
