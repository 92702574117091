@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: block;
    }
    &:after {
        clear: both;
    }
}

@mixin led($startColor) {
    background: $startColor;
    opacity: 0.8;
    filter: alpha(opacity=80);
}
