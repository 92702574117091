.pagination {
    display: flex;
    padding-left: 0;
    margin-left: 0;
    list-style: none;
    justify-content: center;

    .page-item {
        > a {
            @extend .btn;
        }

        &.active > a {
            @extend .btn--primary;
        }

        &.disabled {
            @extend .btn:disabled;
            pointer-events: none;
        }
    }
}
