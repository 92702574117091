form {
    // specific form styles
    input[type="radio"],
    input[type="checkbox"],
    input[type="text"],
    input[type="date"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="search"],
    input[type="password"],
    textarea,
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        box-sizing: border-box;
        display: block;
        width: 100%;
        padding: rem(10px 15px);
        margin-bottom: rem(25px);
        background-color: transparent;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid $light-grey-color;
        transition: border-bottom .3s, padding .3s;

        &::placeholder {
            color: $grey-color;
        }

        &:focus {
            outline: none;
            padding: rem(9px 15px 10px 15px);
            border-color: $green-color;
            border-bottom-width: rem(2px);
        }

        &:disabled {
            opacity: .8;
            cursor: not-allowed;
        }
    }

    select {
        appearance: none;
        cursor: pointer;
        padding-right: rem(25px);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%2313a983' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'%3E%3C/path%3E%3C/svg%3E");
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 13px;

        &:focus {
            padding-right: rem(25px);
        }

        &.select {
            &--width-auto {
                width: auto;
            }
        }
    }

    // checkbox styling
    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        width: auto;

        & + label {
            position: relative;
            cursor: pointer;
            font-size: rem(14px);
            line-height: rem(20px);
            padding-left: rem(30px);
            margin-bottom: rem(20px);

            // checkbox - big square
            &::before {
                background: #dcdcdc;
                display: block;
                position: absolute;
                content: " ";
                margin-right: rem(16px);
                width: rem(20px);
                height: rem(20px);
                left: 0;
            }
        }

        // checkbox hover - big square
        &:hover + label::before {
            background: $light-green-color;
        }

        // checkbox focus (keyboard)
        &:focus + label::before {
            box-shadow: 0 0 0 rem(3px) rgba($dark-green-color, .4);
        }

        /*

        // label in disabeld state
        &[disabled] {
            // no hover effect in disabled state
            &:hover + label::before {
                background: #f2f2f2;
            }

            & + label {
                opacity: .5;
                cursor: not-allowed;

                // checkbox in disabled state
                &::before {
                    box-shadow: none;
                    cursor: not-allowed;
                    opacity: .5;
                }
            }
        }
*/
        // checkbox checked - little red square
        &:checked {
            & + label::after {
                background-color: $blue;
                content: " ";
                position: absolute;
                left: rem(4px);
                top: rem(4px);
                width: rem(12px);
                height: rem(12px);
            }

            // checkbox checked and hover - big square
            &:hover + label::before {
                //box-shadow: 0 0 0 rem(3px) rgba($dark-green-color, .5);
                background: #dcdcdc;
            }
        }
    }

    // radio button styling
    input[type="radio"] {
        & + label {
            // radio - big circle
            &::before {
                border-radius: 50%;
            }
        }

        // radio checked - little red circle
        &:checked {
            & + label::after {
                border-radius: 50%;
            }
        }
    }
}


.btn-group {
    display: flex;
}

.form-group {
    &--error {
        > label {
            color: $red-color;
            animation: error-shake 0.3s 1 linear;
        }

        > textarea,
        > input[type="month"],
        > input[type="number"],
        > input[type="email"],
        > input[type="password"],
        > input[type="text"],
        > select {
            border-bottom-color: $red-color;
            animation: error-shake 0.3s 1 linear;
        }
    }
}

.form-group-error,
.form-error {
    font-weight: bold;
    color: $red-color;
}

.form-group-error {
    margin-top: rem(-15px);
    margin-bottom: rem(25px);
}

@keyframes error-shake {
    0% {
        transform: translate(rem(20px));
    }
    20% {
        transform: translate(rem(-20px));
    }
    40% {
        transform: translate(rem(10px));
    }
    60% {
        transform: translate(rem(-10px));
    }
    80% {
        transform: translate(rem(4px));
    }
    100% {
        transform: translate(rem(0));
    }
}
