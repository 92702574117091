.compute-card {
    transition: border .3s;
    overflow: visible !important;

    &--status-error,
    &--status-build,
    &--status-resize,
    &--status-revert_resize,
    &--status-verify_resize,
    &--status-shutoff {
        border-color: #f4573b !important;
    }

    &--status-active {
        border-color: #50db88 !important;
    }

    &__status-led {
        position: absolute !important;
        top: rem(-1px) !important;
        right: rem(30px) !important;
        left: rem(30px) !important;
        margin: 0 !important;
        width: auto !important;
        height: rem(10px) !important;
        border-radius: rem(0 0 5px 5px) !important;
        border: 1px solid rgba(0, 0, 0, .075) !important;
    }

    &--status-build {
        .compute-card__spinner-wrapper {
            right: rem(30px);
        }
    }

    &__title {
        @extend h2;
        position: relative;
        margin-bottom: 0;
        padding-right: rem(30px);
        padding-left: rem(40px);

        i, svg {
            position: absolute;
            left: 0;
            top: rem(9px);
        }

        &--no-padding {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }

    //&__flavor-info {}

    //&__compute-info {}

    &__settings-toggle-wrapper {
        position: absolute !important;
        right: rem(30px);
        top: rem(33px);
        padding: 0;
    }

    &__settings-toggle {
        color: $grey-color;
    }

    &__spinner-wrapper {
        position: absolute;
        right: rem(80px);
        top: rem(37px);
        padding: 0;
    }

    &__properties-wrapper {
        margin-bottom: rem(20px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__properties-title {
        display: inline-block;
    }

    &__big-text {
        font-size: rem(40px);
        margin-bottom: rem(10px);
    }

    &__subinfo {
        white-space: nowrap;
    }
}
