//
// BaseSetup
//

// native font stacks
$font-family-primary: 'Titillium Web', Helvetica, Verdana, Arial, sans-serif !default;
$font-family-secondary: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif !default;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: $font-family-primary !default;

$font-size-base: rem(16px) !default; // 16px
$font-color-base: black !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

body {
    font-size: $font-size-base;
    font-family: $font-family-base;
    color: $font-color-base;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
}

$h1-font-family: $font-family-base !default;
$h1-font-size: rem(56px) !default;
$h1-line-height: 1.4 !default;
$h1-font-weight: $font-weight-bold !default;
$h1-letter-spacing: rem(0) !default;
$h1-color: $darker-grey-color !default;
$h1-margin-top: rem(0) !default;
$h1-margin-bottom: rem(20px) !default;

$h2-font-family: $font-family-base !default;
$h2-font-size: rem(32px) !default;
$h2-line-height: 1.4 !default;
$h2-font-weight: $font-weight-bold !default;
$h2-letter-spacing: rem(0) !default;
$h2-color: $darker-grey-color !default;
$h2-margin-top: rem(0) !default;
$h2-margin-bottom: rem(20px) !default;

$h3-font-family: $font-family-base !default;
$h3-font-size: rem(22px) !default;
$h3-line-height: 1.6 !default;
$h3-font-weight: $font-weight-normal !default;
$h3-letter-spacing: rem(0) !default;
$h3-color: $darker-grey-color !default;
$h3-margin-top: rem(0) !default;
$h3-margin-bottom: rem(10px) !default;

$h4-font-family: $font-family-base !default;
$h4-font-size: rem(18px) !default;
$h4-line-height: 1.5 !default;
$h4-font-weight: $font-weight-bold !default;
$h4-letter-spacing: rem(0) !default;
$h4-color: $darker-grey-color !default;
$h4-margin-top: rem(0) !default;
$h4-margin-bottom: rem(0) !default;

h1, .h1-style {
    font-family: $h1-font-family;
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    line-height: $h1-line-height;
    letter-spacing: $h1-letter-spacing;
    color: $h1-color;
    margin-top: $h1-margin-top;
    margin-bottom: $h1-margin-bottom;
}

h2, .h2-style {
    font-family: $h2-font-family;
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    line-height: $h2-line-height;
    letter-spacing: $h2-letter-spacing;
    color: $h2-color;
    margin-top: $h2-margin-top;
    margin-bottom: $h2-margin-bottom;
}

h3, .h3-style {
    font-family: $h3-font-family;
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: $h3-line-height;
    letter-spacing: $h3-letter-spacing;
    color: $h3-color;
    margin-top: $h3-margin-top;
    margin-bottom: $h3-margin-bottom;
}

h4, .h4-style {
    font-family: $h4-font-family;
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
    line-height: $h4-line-height;
    letter-spacing: $h4-letter-spacing;
    color: $h4-color;
    margin-top: $h4-margin-top;
    margin-bottom: $h4-margin-bottom;
}

//
// Paragraphs
//

$paragraph-margin-bottom: rem(20px) !default;
$paragraph-font-size: inherit !default;
$paragraph-font-weight: $font-weight-base !default;
$paragraph-color: $font-color-base !default;

p {
    font-size: $paragraph-font-size;
    font-weight: $paragraph-font-weight;
    margin-bottom: $paragraph-margin-bottom;
    color: $paragraph-color;
}

b,
strong {
    font-weight: $font-weight-bold;
}

i {
    font-style: italic;
}

//
// Links
//

$link-text-decoration: none !default;
$link-text-decoration-hover: underline !default;
$link-color: $dark-green-color !default;
$link-color-hover: $green-color !default;

a {
    color: $link-color;
    text-decoration: $link-text-decoration;

    &.pseudo-class-hover,
    &:hover {
        color: $link-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
}

//
// Lists
//

$unordered-list-style-type: disc !default;
$unordered-list-margin-bottom: rem(20px) !default;
$unordered-list-font-size: inherit !default;
$unordered-list-font-weight: $font-weight-base !default;
$unordered-list-color: $font-color-base !default;
$unordered-list-padding-left: rem(20px) !default;

$unordered-list-items-padding-left: rem(0) !default;
$unordered-list-items-margin-bottom: rem(0) !default;

ul {
    list-style-type: $unordered-list-style-type;
    font-size: $unordered-list-font-size;
    font-weight: $unordered-list-font-weight;
    margin-bottom: $unordered-list-margin-bottom;
    color: $unordered-list-color;
    padding-left: $unordered-list-padding-left;

    li {
        padding-left: $unordered-list-items-padding-left;
        margin-bottom: $unordered-list-items-margin-bottom;
    }
}

$ordered-list-style-type: decimal !default;
$ordered-list-margin-bottom: rem(20px) !default;
$ordered-list-font-size: inherit !default;
$ordered-list-font-weight: $font-weight-base !default;
$ordered-list-color: $font-color-base !default;
$ordered-list-padding-left: rem(20px) !default;

$ordered-list-items-padding-left: rem(0) !default;
$ordered-list-items-margin-bottom: rem(0) !default;

ol {
    list-style-type: $ordered-list-style-type;
    font-size: $ordered-list-font-size;
    font-weight: $ordered-list-font-weight;
    margin-bottom: $ordered-list-margin-bottom;
    color: $ordered-list-color;
    padding-left: $ordered-list-padding-left;

    li {
        padding-left: $ordered-list-items-padding-left;
        margin-bottom: $ordered-list-items-margin-bottom;
    }
}
