$dashboard__sidebar--width: rem(265px) !default;
$dashboard__top-bar--height: rem(80px) !default;

$dashboard__sidebar-background: $dark-blue !default;
$dashboard__sidebar-toggle: $dark-blue !default;

.dashboard {
    display: flex;
    margin-left: 0;
    transition: margin-left .5s $easeOutExpo;

    &--sidebar-open {
        @include media-breakpoint-up(lg) {
            margin-left: $dashboard__sidebar--width;
        }

        .dashboard__sidebar {
            transform: translate(0, 0);
        }

        .dashboard__sidebar-toggle {
            left: $dashboard__sidebar--width;
        }

        .dashboard__content-main {
            @include media-breakpoint-between(lg, lg) {
                left: $dashboard__sidebar--width;
                width: calc(100% - #{$dashboard__sidebar--width});
            }
        }
    }

    &__sidebar {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        width: $dashboard__sidebar--width;
        padding: 0;
        flex: 0 0 auto;
        background: $dashboard__sidebar-background;
        color: $white-color;
        z-index: 10;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transform: translate(-100%, 0);
        transition: transform .5s $easeOutExpo;
    }

    &__sidebar-header {
        display: flex;
        height: $dashboard__top-bar--height;
        align-items: center;
        background: darken($dashboard__sidebar-background, 4%);
        text-transform: uppercase;
        font-size: rem(18px);
        padding-left: 30px;
    }

    &__sidebar-toggle {
        @extend .btn;
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        z-index: 100;
        width: 40px;
        height: 40px;
        border: none;
        border-radius: 0 3px 0 0;
        color: $dashboard__sidebar-toggle;
        background: $white-color;
        border-top: 1px solid $light-grey-color;
        border-right: 1px solid $light-grey-color;
        transition: color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out,
        box-shadow .15s ease-in-out,
        left .5s $easeOutExpo;

        &:focus,
        &:hover {
            color: white !important;
            background: $dashboard__sidebar-toggle;
        }

        > svg {
            width: 25px;
        }
    }

    &__top-bar {
        display: flex;
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: $dashboard__top-bar--height;
        padding: 0 30px;
        background: $white-color;
        box-shadow: 0 -2px 12px rgba(0, 0, 0, .4);
        justify-content: space-between;
        z-index: 5;

        > a {
            display: flex;
            align-content: center;
        }
    }

    &__content-area {
        flex: 1 1 auto;
    }

    &__content-main {
        box-sizing: border-box;
        padding: 60px;
        width: 100%;
        height: calc(100vh - #{$dashboard__top-bar--height});
        background: #F7F7F7;
        overflow-y: auto;

        @include media-breakpoint-down(lg) {
            height: auto;
            position: absolute;
            top: $dashboard__top-bar--height;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 60px 30px;
            -webkit-overflow-scrolling: touch;
        }
    }
}

/* margin-bottom only for firefox */
@-moz-document url-prefix() {
    .dashboard {
        &__content-main {
            > * {
                margin-bottom: 60px;
            }
        }
    }
}
