.ReactModal {
    &__Overlay {
        cursor: pointer;
        opacity: 0;
        transition: opacity .25s;

        &--after-open {
            overflow: auto;
            opacity: 1;
        }

        &--before-close {
            opacity: 0;
        }
    }

    &__Content {
        cursor: initial;
        opacity: 0 !important;
        transform: translate(-50%, -200px) !important;
        transition: transform .5s cubic-bezier(0.175, 0.885, 0.320, 1), opacity .5s;

        &.ReactModal__Content--after-open {
            opacity: 1 !important;
            transform: translate(-50%, 0) !important;
        }

        &.ReactModal__Content--before-close {
            opacity: 0 !important;
            transform: translate(-50%, 50vh) !important;
        }
    }
}
