// helper classes
$properties: ('p', 'padding'),
        ('m', 'margin') !default;

$direction: ('l', 'left'),
        ('r', 'right'),
        ('t', 'top'),
        ('b', 'bottom') !default;

$sizes: (0, 0),
        (1, rem(5px)),
        (2, rem(10px)),
        (3, rem(15px)),
        (4, rem(20px)),
        (5, rem(25px)),
        (6, rem(30px)),
        (7, rem(35px)),
        (8, rem(40px)),
        (9, rem(45px)),
        (10, rem(50px)),
        (11, rem(55px)),
        (12, rem(60px)) !default;

.clear {
    clear: both;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.ta-l {
    text-align: left;
}

.ta-r {
    text-align: right;
}

.ta-c {
    text-align: center;
}

// helper class to guarantee flex-direction row
// useCase is for example on Column components if you want do deactivate default equalizer => <Column className="flex-row"> ... </Column>
.flex-row {
    flex-direction: row !important;
}

/**
helper spacing classes
for margin and padding

margin:
    .ml-1 = margin-left: 5px
    .mt-2 = margin-top: 10px
    .mb-3 = margin-bottom: 15px
    .mb-4 = margin-bottom: 20px
    .mb-5 = margin-bottom: 25px
    .mr-6 = margin-right: 30px

padding:
    .pl-1 = padding-left: 5px
    .pt-2 = padding-top: 10px
    .pb-3 = padding-bottom: 15px
    .pl-4 = padding-left: 20px
    .pb-5 = padding-bottom: 25px
    .pr-6 = padding-right: 30px
 */
@each $propertyClassname, $property in $properties {
    @each $directionClassname, $propertyName in $direction {
        @each $sizeModifier, $size in $sizes {
            .#{$propertyClassname}#{$directionClassname}-#{$sizeModifier} {
                #{$property}-#{$propertyName}: #{$size} !important;
            }
        }
    }
}


/**
helper hiding classes
 */
$hide-breakpoints: (xs, sm, md, lg, xl, xxl);
@each $breakpointName in $hide-breakpoints {
    .hide-#{$breakpointName} {
        @include media-breakpoint-down(#{$breakpointName}) {
            display: none !important;
        }
    }
}