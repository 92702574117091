.empty-group {
    display: block;
    width: 100%;
    border: rem(1px) solid $grey-color;
    background-color: $white-color;
    box-shadow: rem(0 1px 4px 0) rgba(0, 0, 0, 0.1);
    padding: rem(40px);
    text-align: center;
    border-radius: rem(4px);

    svg {
        color: $blue;
        margin-bottom: rem(15px);
    }
}