$rc-collapse-content--background: #242a33 !default;
$rc-collapse-header--border-top-color: rgba(#fff, .2) !default;

.rc-collapse {
    > .rc-collapse-item {
        &.rc-collapse-item-active {
            > .rc-collapse-header {
                .arrow {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z'%3E%3C/path%3E%3C/svg%3E");
                }
            }
        }

        > .rc-collapse-header {
            position: relative;
            display: flex;
            align-items: center;
            padding: rem(16px 25px 16px 25px);
            color: $white-color;
            cursor: pointer;
            border-top: 1px solid $rc-collapse-header--border-top-color;

            .arrow {
                position: absolute;
                display: block;
                width: rem(20px);
                height: rem(20px);
                right: rem(25px);
                vertical-align: middle;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23ffffff' d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'%3E%3C/path%3E%3C/svg%3E");
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        &:last-child {
            .rc-collapse-content {
                border-bottom: 1px solid $rc-collapse-header--border-top-color;
            }
        }
    }
}

.rc-collapse-content-inactive {
    display: none;
}

.rc-collapse-content {
    overflow: hidden;
    background: darken($rc-collapse-content--background, 2%);
}

.rc-collapse-anim-active {
    transition: height 0.2s ease-out;
}
