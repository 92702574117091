.login {
    @extend .container;
    position: relative;
    max-width: rem(460px);
    margin-top: rem(120px);
    margin-bottom: rem(120px);

    @include media-breakpoint-down(lg) {
        margin-top: rem(60px);
        margin-bottom: rem(60px);
    }

    &--is-busy {
        box-shadow: 0 0 5px rgba($black-color, .05);

        img,
        form {
            opacity: .25;
        }

        .loader {
            display: block !important;
        }
    }

    .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: rem(40px);
    }

    h1 {
        margin-top: 0;
        padding-top: 0;
    }

    .btn {
        width: 100%;
        margin-bottom: rem(25px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    img,
    form {
        transition: opacity .3s $easeOutExpo;
    }

    .loader {
        display: none;
    }
}


$loaderWidthHeight: 40px;
$loaderFillColor: rgba($green-color, 1);
$loaderSpeed: 0.5s;

/**
** Loading Spinner SVG
** © Sebastian Richter
**/
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -($loaderWidthHeight/2);
    margin-left: -($loaderWidthHeight/2);
    width: $loaderWidthHeight;
    height: $loaderWidthHeight;

    path {
        fill: $loaderFillColor;
        transform-origin: 25px 25px;
        animation: spin $loaderSpeed linear infinite;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
