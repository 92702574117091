%container {
    display: block;
    width: 100%;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
}

.container-fluid {
    @extend %container;
}

.container {
    @extend %container;
    max-width: $xxl;
    margin-left: auto;
    margin-right: auto;
}
