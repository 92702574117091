.main-navigation {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;

    > li {
        &:first-child {
            border-top: 1px solid rgba($white-color, .2);

        }

        &.active > a {
            text-decoration: none;
            color: $white-color;
        }
    }

    a {
        display: block;
        padding: 16px 5px 16px 25px;
        font-size: 17px;
        color: rgba($white-color, .3);
        text-decoration: none;
        background-color: transparent;
        transition: color .3s;

        &:hover {
            text-decoration: none;
            color: $white-color;
        }
    }
}
