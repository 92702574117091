.rc-steps {
    .rc-steps-item.rc-steps-item-finish {
        color: $light-green-color;

        .rc-steps-item-icon {
            position: relative;
            border-color: $light-green-color;
            background: $light-green-color;

            &::before {
                position: absolute;
                display: block;
                content: " ";
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                background-size: 50%;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='white' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
            }
        }
    }

    .rc-steps-item-title {
        @include media-breakpoint-down(sm) {
            display: none !important;
        }
    }
}
