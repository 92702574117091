.card {
    clear: both;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    padding: 0;
    background-color: white;
    background-clip: border-box;
    border-radius: rem(4px);
    border: 1px solid rgba(0, 0, 0, .125);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: rem(30px);
    overflow: hidden;
    flex: 1 0 auto;

    &--no-border {
        border: none;
        box-shadow: none;
    }

    &__image {
        display: block;
        background: $light-grey-color;
    }

    &__title {
        position: relative;
        margin-bottom: rem(15px);
    }

    &__body {
        flex: 1 1 auto;
        padding: rem(30px);
    }

    &__toggle {
        position: relative;
        padding: rem(10px 15px);
        margin-left: rem(5px);
        background-color: #f3f3f3;
        background-clip: border-box;
        border-radius: rem(4px 4px 0 0);
        border: 1px solid rgba(0, 0, 0, .125);
        border-bottom: none;
        cursor: pointer;
        z-index: 1;

        &:first-of-type {
            margin-left: 15px !important;
        }

        &:focus {
            outline: none;
        }

        &--active {
            background-color: white;

            &::before {
                position: absolute;
                content: " ";
                bottom: -3px;
                left: 0;
                right: 0;
                background: inherit;
                height: rem(3px);
            }
        }
    }
}
