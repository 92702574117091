.btn-group {
    > button {
        &:first-child {
            border-radius: rem(4px 0 0 4px);
        }

        &:last-child {
            border-radius: rem(0 4px 4px 0);
        }

        &:first-child:last-child {
            border-radius: rem(4px);
        }
    }

    &--full-width {
        display: flex;
        width: 100%;

        > button {
            flex: 1 0 auto;
        }
    }
}


.btn {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    padding: rem(6px 12px);
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: rem(3px);
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, opacity .15s ease-in-out;

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
        color: $light-green-color;
        text-decoration: none;
    }

    &:focus:not(:disabled) {
        outline: none;
        box-shadow: 0 0 0 .2rem rgba($dark-grey-color, .2);
    }

    &:disabled {
        opacity: .4;
        cursor: not-allowed;
    }

    &--primary {
        color: $white-color;
        background-color: $green-color;

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            color: $white-color;
            background-color: rgba($green-color, 0.7);
        }
    }

    &--secondary {
        color: $white-color;
        background-color: $dark-grey-color;

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            color: $white-color;
            background-color: rgba($dark-grey-color, 0.7);
        }
    }

    &--big {
        padding: rem(6px 25px);
        font-size: rem(20px);
    }

    &--danger {
        color: $white-color;
        background-color: $red-color;

        &:focus:not(:disabled),
        &:hover:not(:disabled) {
            color: $white-color;
            background-color: rgba($red-color, 0.7);
            cursor: pointer;
        }
    }

    /*
    &--secondary {
        background-color: $dark-grey-color;

        &:hover {
            background-color: rgba($dark-grey-color, 0.7);
            cursor: pointer;
        }
    }

    &--login{
        margin-left: 0;
        margin-bottom: rem(20px);
        width: 100%;
    }
    */
}
