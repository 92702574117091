.flavor-price {
    font-size: rem(24px);
    font-weight: bold;

    small {
        font-weight: normal;
        font-size: rem(16px);
    }

    &--input {
        input {
            display: inline !important;
            width: 80px !important;
            margin: 0 !important;
            padding: 0 !important;
            font-weight: bold;
            text-align: right;
            color: inherit !important;
        }
    }
}

.radio-card {
    .radio-card__header {
        .flavor-logo {
            opacity: .6;
            transition: opacity .3s;
        }
    }

    > input:checked + label {
        .radio-card__header {
            .flavor-logo {
                opacity: 1;
            }
        }
    }
}

.flavor-logo {
    display: block;
    width: 150px;
    max-height: 70px;
}