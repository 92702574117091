/** Project Primary Colors **/
$green-color: #13a983 !default;
$white-color: #ffffff !default;
$black-color: #000000 !default;

/** Project Secondary Colors **/
$dark-green-color: #009171 !default;
$light-green-color: #79c3a9 !default;
$darker-grey-color: #4c4f5b !default;
$dark-grey-color: #999999 !default;
$grey-color: #aaaaaa !default;
$light-grey-color: #dddddd !default;

$red-color: red !default;

$dark-blue: #242a33 !default;
$blue: #0976ff !default;
;
