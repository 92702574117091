.support {
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}
