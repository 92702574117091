// Pluswerk AG
// Responsive Flexbox Grid
// Sebastian Richter <sebastian.richter@pluswerk.ag>

@mixin make-row() {
    display: flex;
    flex-wrap: wrap;
    margin-right: ($grid-gutter-width / 2) *-1;
    margin-left: ($grid-gutter-width / 2) *-1;
}

@mixin make-column($size, $columns) {
    flex-basis: percentage($size / $columns);
    width: percentage($size / $columns);
    max-width: percentage($size / $columns);
    padding-right: ($grid-gutter-width / 2);
    padding-left: ($grid-gutter-width / 2);
}

@mixin make-column-offset($size, $columns: $grid-cols) {
    $num: $size / $columns;
    margin-left: if($num == 0, 0, percentage($num));
}
