$greenStartColor: #50db88;
$redStartColor: #f4573b;

.status-led {
    position: relative;
    top: rem(4px);
    display: inline-block;
    width: rem(20px);
    height: rem(20px);
    border-radius: 50%;
    margin-right: rem(8px);
    border: 1px solid $green-color;
    background: transparent;
    transition: all .5s;

    @include media-breakpoint-down(md) {
        top: 0;
    }

    &--active {
        @include led($greenStartColor);
    }

    &--error,
    &--build,
    &--resize,
    &--revert_resize,
    &--verify_resize,
    &--shutoff {
        @include led($redStartColor);
    }

    &--revert_resize,
    &--verify_resize,
    &--resize,
    &--build {
        animation: pulseLed 2s linear infinite;
    }
}

@keyframes pulseLed {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}