.loading-spinner {
    animation: spin .75s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}